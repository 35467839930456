/* src/styles/animations.css */
.slide-enter {
	transform: translateY(-100%); /* Start from above */
	opacity: 0; /* Start transparent */
}

.slide-enter-active {
	transform: translateY(0); /* Slide into place */
	opacity: 1; /* Fade in */
	transition: transform 3000ms ease-in-out, opacity 3000ms ease-in-out; /* Smooth transition */
}

.slide-exit {
	transform: translateY(0); /* Start in place */
	opacity: 1; /* Fully visible */
}

.slide-exit-active {
	transform: translateY(100%); /* Slide down out of view */
	opacity: 0; /* Fade out */
	transition: transform 3000ms ease-in-out, opacity 3000ms ease-in-out; /* Smooth transition */
}

/* Adjusted for parent fade and child slide effects */

.fade-enter {
	opacity: 0; /* Start transparent */
	/* No transform for parent */
}
.fade-enter-active {
	opacity: 1; /* Fade in */
	transition: opacity 500ms !important; /* Smooth transition for fade */
}

.fade-exit {
	opacity: 1; /* Fully visible */
	/* No transform for parent */
}
.fade-exit-active {
	opacity: 0; /* Fade out */
	transition: opacity 500ms !important; /* Smooth transition for fade */
}

.fade-enter .fade-this {
	transform: translateY(-100%); /* Slide in from left */
}
.fade-enter-active .fade-this {
	transform: translateY(0%); /* Slide into place */
	transition: transform 500ms; /* Smooth transition for slide */
}
.fade-exit .fade-this {
	transform: translateY(0%); /* Start in place */
}
.fade-exit-active .fade-this {
	transform: translateY(100%); /* Slide out to the right */
	transition: transform 500ms; /* Smooth transition for slide */
}
