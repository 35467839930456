#tiers .tier-row {
	@apply rounded-md 
	w-full
    /* max-w-sm  */
    /* flex flex-col items-center */
    /* p-5 relative my-12  */
    /* justify-between  */
    border-2 border-solid border-e_blue
     hover:bg-opacity-50 
    hover:scale-105
    ease-in-out;
	/* flex: 0 0 45%; */
	/* border: 2px solid #ccc; */
	transition: all 200ms ease-in-out;
	/* min-width: 35vw; */
	/* max-width: fit-content; */
	/* max-height: 95vh; */
	/* justify-content: space-between; */
	/* align-items: stretch; */
}

#tiers button1 {
	@apply hover:outline-e_blue inline-block 
    border-none rounded
    bg-e_blue text-main cursor-pointer
    pb-2 px-4 
	pt-3
    /* my-4 */
    /* font-bold  */
    appearance-none select-none
    duration-200 ease-in-out transition-all;
	/* transition: all 200ms ease-in-out; */
	/* align-self: center; */
	/* text-decoration: none; */

	/* -webkit-appearance: none;
	-moz-appearance: none;
	appearance: none; */
	/* line-height: initial; */
	/* -webkit-touch-callout: none;
	-webkit-user-select: none;
    user-select: none; */
}
#tiers .tier-row:hover button {
	@apply bg-white text-e_blue outline outline-e_blue outline-4 rounded-md;
}
/* end tiers */
