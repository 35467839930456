@tailwind base;
@tailwind components;
@tailwind utilities;

/* @font-face {
	font-family: 'Makasih';
	src: local('Makasih'), url(../public/assets/fonts/Makasih.ttf) format('truetype');
}
@font-face {
	font-family: 'Makasih';
	src: local('Makasih'), url(../public/assets/fonts/Makasih.otf) format('opentype');
} */
/* @font-face {
	font-family: 'LuckiestGuy';
	src: local('LuckiestGuy'), url(../public/assets/fonts/LuckiestGuy.ttf) format('truetype');
} */
* {
	/* outline: 1px solid rgb(255, 157, 0) !important; */
	user-select: text;
	/* @apply transition-all duration-200 ease-in-out; */
}
*,
::after,
::before {
	box-sizing: border-box;
	background-repeat: no-repeat;
}

::after,
::before {
	text-decoration: inherit;
	vertical-align: inherit;
}

img {
	/* @apply !pointer-events-none !select-none; */
}
/* #root {
	max-width: 100vmin;
	max-height: 100vmin;
	@apply relative w-full;
} */
:root {
	max-width: 100svw;
	/* height: 100vmin; */

	overflow-x: hidden;

	--font-lucky: LuckiestGuy, -apple-system, 'Roboto', 'Oxygen', 'Ubuntu', 'Helvetica Neue', sans-serif;
	/* --font-adelez: Makasih, -apple-system, 'Roboto', 'Oxygen', 'Ubuntu', 'Helvetica Neue', sans-serif; */
	--default-fonts: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
	--font-regular: var(--default-fonts);

	--c-red: #c41116;
	--c-green: #22c55e;

	font-family: var(--font-regular);
	text-decoration-skip-ink: 'auto';
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	caret-color: transparent;
	-webkit-tap-highlight-color: transparent;
	-webkit-text-size-adjust: 100%;
	-moz-text-size-adjust: 100%;
	text-size-adjust: 100%;
	text-rendering: optimizeLegibility;
	overflow-wrap: break-word;
	cursor: default;
	-moz-tab-size: 4;
	-o-tab-size: 4;
	tab-size: 4;
	/* contact timeings */
	/* timing of each side,.. 
    delay to syncronize sides... 
    bottom, left, top, right */
	--timeing: 0.1s;
	--delay-1: 0.1s;
	--delay-2: 0.2s;
	--delay-3: 0.3s;
	--delay-4: 0.4s;
}
body.blockSiteScrollingWithOverflow {
	overflow-y: hidden;
}
/* general */
h1.section-title {
	@apply z-30 mt-8 relative w-screen flex justify-center font-lucky text-5xl md:text-7xl tracking-wide md:tracking-wider
	max-w-[100svw] px-12 overflow-hidden !leading-normal;
}
.doodle,
.doodle img,
.doodle svg {
	@apply z-20 h-auto select-none touch-none pointer-events-none;
}
.progress {
	@apply h-1 bg-e_orange bg-opacity-50 fixed top-0 left-0 z-50;
	/* height: 3px; */
	/* width: var(--scroll); */
}
.flex-to-center {
	@apply flex content-center items-center justify-center;
}
.noscroll {
	overflow: hidden;
}
/* hero */
/* hero */
/* hero */

/* gallery */
#gallery .gallery-section {
	@apply relative overflow-hidden flex flex-col justify-center items-center w-full;
}
#gallery .info-section {
	@apply md:grid md:grid-cols-3 grid-flow-col-dense pt-6 md:pt-0
	flex flex-col justify-center z-20 relative items-end;
}

#gallery .image-grid {
	@apply max-w-screen-lg relative 
	grid grid-cols-1 md:grid-cols-3 px-6
	gap-4 
	object-cover object-center 
	justify-center h-full
	content-center
	items-center
	pt-4
	transition-all duration-300 ease-in-out;
}
#gallery .image-grid-item {
	@apply relative overflow-hidden hover:overflow-visible hover:z-10 transition-all duration-300 ease-in-out h-full w-auto max-h-[80vh];
	/* box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.3);
	-webkit-box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.3);
	-moz-box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.3); */
}
/* howto */
/* faq */

#faq .faq {
	@apply my-4 mx-3 md:mx-2 p-4 md:p-3 bg-main rounded-lg shadow drop-shadow-md hover:shadow-e_orange;
}
#faq .title {
	@apply text-offwhite text-center bg-transparent;
}
#faq .faq-question {
	/* position: relative; */
	/* font-size: calc(var(--f-txt) + 0.3vmin); */
	/* padding-right: 20px; */
	transition: all 0.4s ease;
}
.faqs .faq .faq-question::after {
	font-weight: 700;
	content: '+';
	position: absolute;
	top: 0;
	right: 5px;
	transition: all 0.2s ease;
}

#faq .faq .faq-answer {
	opacity: 0;
	max-height: 0;
	font-size: var(--f-txt);
	overflow: hidden;
	transition: all 0.2s ease;
	/* white-space: pre-line; */
}
#faq .faq .faq-answer a {
	@apply text-e_orange;
	/* text-decoration: underline; */
	cursor: pointer;
}
#faq .faq .faq-answer a:hover {
	/* opacity: 0.7; */
	text-decoration: underline;
}
#faq .faq.open .faq-question {
	margin-bottom: 15px;
}

/* #faq .faq.open .faq-question::after {
	content: '-';
} */

#faq .faq.open .faq-answer {
	height: max-content;
	max-height: 500px;
	overflow-y: auto;
	opacity: 1;
}

/* this section doesnt get bigget then 768px, so this is the only query we need */
/* @media only screen and (min-width: 480px) {
	#faq .faq .faq-question::after {
		right: 10px;
	}
} */

/* contact */
#contact * {
	@apply transition-all duration-200;
}
#contact .form_section {
	/* todo maybe change md: which is 768px to 992px or something else*/
	@apply flex flex-col items-start md:w-4/6 w-11/12 mr-0 md:mr-5 max-w-2xl;
	/* max-width: 600px; */
	/* TODO need to find a way to handle this in a fluid responsive way, instead of forcing the screen to be bigger then 300px */
	min-width: 300px;
}

/* basic sizing for the doodle, changing the doodle div size will control its size */
#contact .doodle svg {
	@apply fill-contact w-full h-full;

	max-height: clamp(20vh, 400px, 50vh);
	max-width: clamp(40vw, 200px, 80vw);
}
/* footer */
footer {
	@apply /* bg-bg_dark TODO COLOR CHANGE */
	flex-wrap flex flex-col h-full text-center items-center justify-between pointer-events-auto;
}

footer svg {
	transition: transform 0.2s ease-in-out;
}

footer .social-buttons svg:hover {
	transform: scale(1.5);
	opacity: 0.7;
	pointer-events: all;
}
footer .social-buttons {
	/* place the social buttons on the top with some witty text above it (i think), on small screens, on bigger screens I leave it up to you */
	order: 2;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
}
footer .img {
	/* place the doodle on the right on bigger screens */
	order: 1;
	/* transform: rotateY(180deg); */
}
footer .img .doodle {
	fill: white;
}
footer .copyright {
	/* place the copyright in the bottom on big screens, either in the middle or to the left */
	order: 3;
	text-align: left;
}

/* on smaller screens, center the image and stack the social buttons and copyright vertically */
@media (max-width: 768px) {
	footer {
		flex-direction: column;
	}
}
/* end footer */
