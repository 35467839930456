#contact {
	background-color: var(--c-bg-contact);
	/* min-height: 50vh; */
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	/* padding: 50rem 0; */
	animation: all 1s ease-in-out 0s both;
	position: relative;
	font-weight: 400;
	line-height: 1.563;
	color: var(--c-text);
	flex-wrap: wrap;
	/* overflow: visible; */
}

/* basic sizing for the doodle, changing the doodle div size will control its size */
#contact .doodle svg {
	/* fill: var(--c-doodle-contact); */
	/* width: auto;
	height: 100%; */
	/* max-height: 40vh;
	min-width: 40vw; */
}

/* #contact .contact_method {
	text-align: center;
	flex-basis: 100%;
	width: 100%;
} */
#contact form {
	/* padding-left: 1rem; */
	/* position: relative; */
}
/* #contact form ~ .doodle {
	display: flex;
	align-self: flex-end;
	justify-self: flex-end;

	position: sticky;
	right: 0;
	bottom: 0;
} */
