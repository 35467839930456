.logo {
	@apply flex flex-col md:flex-row flex-wrap justify-center content-center items-center transition-all duration-300;
}
.logo .a_sign {
	@apply transition-all duration-300 h-10 px-4 my-2;
}
.logo .text {
	@apply w-auto h-5 my-4 transition-all duration-300 opacity-100;
}
.logo .text.hide {
	@apply opacity-0; /* Keep opacity transition */
	@apply transition-all duration-300; /* Ensure transition is applied */
	/* Animation for height collapse towards the top */
	@apply h-0 transform -translate-y-full; /* Collapse height */
	@apply my-0;
	@apply w-0 -translate-x-full
	/* @apply md:w-full md:translate-x-0; */;
}
/* .logo .a_sign.onHero {
	@apply my-2;
} */
.logo .text.onHero {
	@apply opacity-0 w-0 my-0 h-0 transform -translate-y-full -translate-x-full;
}
