form {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-content: center;
	align-items: center;
	transition: all 0.3s;
}
/* ::selection {
	background: #708090;
	color: #fff !important;
} */

#contact label {
	@apply pb-3 sm:px-4 font-bold flex flex-col relative w-full;
	/* max-w-[400px] lg:max-w-[500px]; */
}

#contact .form_field {
	--border-color: var(--c-doodle-contact);
}
#contact .form_field:has(.input_area),
#contact .form_field:has(.input_area.valid) {
	/* @apply border-e_green; */
	--border-color: var(--c-green);
}

#contact .form_field:has(.input_area.invalid) {
	/* @apply border-e_red; */
	--border-color: var(--c-red);
}
/* transition-duration: 0; */

/* #contact .form_field .error {
} */
#contact .form_field .input_area {
	@apply outline-0 
	bg-transparent 
    appearance-none 
    outline-none
    /* rounded-t-xl  */
    /* origin-top-left */
    caret-black
    px-3
    py-2
	/* border-solid  */
	/* border-[color:var(--border-color)]  */
	/* border-0 border-b-2 */
    /* text-text_bh TODO COLOR CHANGE*/
    font-normal
    /* focus-visible:outline-0 */
    /* focus-visible:border-transparent */
    /* focus-visible:bg-offwhite */
    /* placeholder:text-slate-400 */
    /* placeholder:text-base */
    /* focus:border-red */
    /* focus-visible:border-2 */
    w-full;

	-webkit-appearance: none;
	-moz-appearance: none;
	/* appearance: none; */
}
/*  before - top 
    after - bottom */
#contact .form_field .input_area + .focus-border {
	@apply before:absolute 
    z-50
    after:absolute 
    before:top-0 
    before:right-0
    after:bottom-0 
    after:left-0
    before:w-0 
    rounded-t-xl
    transition-width
    /* changing this to 100 from 0, to make it always full */
    after:w-full
    before:h-[2px]
    after:h-[2px]
    before:bg-[color:var(--border-color)]
    after:bg-[color:var(--border-color)]
    before:delay-[var(--delay-2)] 
    after:delay-[var(--delay-4)] 
    before:duration-[var(--timeing)]
    after:duration-[var(--timeing)];
}

#contact .form_field .input_area:focus + .focus-border {
	@apply before:w-full
    after:w-full
    before:delay-[var(--delay-3)] 
    after:delay-[var(--delay-1)] 
    before:duration-[var(--timeing)]
    after:duration-[var(--timeing)];
}
/*  i:before - right
    i:after - after */
#contact .form_field .input_area + .focus-border i {
	@apply before:absolute 
    after:absolute 

    after:top-0 
    after:left-0
    
    before:bottom-0
    before:right-0 

    before:h-0 
    after:h-0 

    before:w-[2px]
    after:w-[2px]

    before:bg-[color:var(--border-color)]
    after:bg-[color:var(--border-color)]

    before:delay-[var(--delay-3)] 
    after:delay-[var(--delay-1)] 
    before:duration-[var(--timeing)]
    after:duration-[var(--timeing)];
}

#contact .form_field .input_area:focus + .focus-border i {
	@apply before:h-full
    after:h-full
    before:delay-[var(--delay-2)] 
    after:delay-[var(--delay-4)] 
    before:duration-[var(--timeing)]
    after:duration-[var(--timeing)];
}

/* form textarea {
	min-height: 125px;
}
form textarea:focus {
	min-height: 150px;
} */

/* TODO might be nice to flip the arrow when its in :focus */
#contact form select {
	background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 125 125"><path fill="currentColor" d="M63 104 0 21h125"/></svg>');
	background-repeat: no-repeat;
	background-size: 1rem;
	background-position: center right 0.5rem;
}
/* form select:focus {
	background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 125.304 125.304"><path d="M62.652 103.895L0 21.41h125.304" fill="%23343334"/></svg>');
	background-repeat: no-repeat;
	background-size: 2rem;
	background-position: center right 0.5rem;
} */

/* form textarea {
	resize: vertical;
} */

#contact .after_message {
	@apply mt-1 m-4 self-start;
}
#contact .after_message.error {
	@apply text-e_red;
}
#contact form .error {
	@apply mt-1 text-e_red self-start whitespace-pre-wrap;
	/* color: red; */
	/* align-self: flex-start; */
	/* margin-top: 0.25rem; */
}
/* [data-theme='dark'] form .error {
	color: red;
} */
#contact form button:disabled {
	/* @apply cursor-not-allowed; */
	opacity: 0.5;
	cursor: not-allowed !important;
}
#contact form button[type='submit'] {
	/* @apply px-2 py-5  */
	/* bg-cta TODO COLOR CHANGE */
	/* text-main rounded-md mx-4 my-auto 
    appearance-none select-none 
    cursor-pointer 
    transition-all duration-200
    hover:bg-[color:var(--btn-color)]  
    hover:outline-[color:var(--btn-color)] 
    hover:outline 
    hover:outline-2
    focus-visible:outline-[color:var(--btn-color)] 
    focus-visible:outline 
    focus-visible:outline-2 */
	/* hover:text-main */
	/* focus-visible:outline-none */

	/* transition: background-color 200ms ease-in-out, border 200ms ease-in-out, transform 200ms ease-in-out; */
	/* -webkit-touch-callout: none;
	-webkit-user-select: none;
	user-select: none; */
	/* --btn-color: var(--c-cta); */
}
/* form button[type='submit'].loading::after {
	content: '\25E0';
	@apply animate-spin;
} */
/* form button[type='submit']:hover {
	@apply bg-[color:var(--btn-color)] outline-[color:var(--btn-color)] outline outline-2;
}
form button[type='submit']:focus-visible {
	@apply bg-[color:var(--btn-color)] outline-none;
} */

/* not sure ill use a checkbox, but just in case  */
/* form input[type='checkbox'] {
	height: 1rem;
	border-radius: 5px;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	border-bottom: 2px solid gray;
	width: 1rem;
	background-color: white;
	align-self: center;
	margin-right: 0.5rem;
}
form input[type='checkbox']:hover {
	cursor: pointer;
}
form input[type='checkbox']:checked {
	background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="78.369" height="78.369" viewBox="0 0 78.369 78.369"><path fill="white" d="M78.05 19.015l-48.592 48.59c-.428.43-1.12.43-1.548 0L.32 40.016c-.427-.426-.427-1.12 0-1.547l6.704-6.704c.428-.427 1.12-.427 1.548 0l20.113 20.112 41.113-41.113c.43-.427 1.12-.427 1.548 0l6.703 6.704c.427.427.427 1.12 0 1.548z"/></svg>');
	background-size: contain;
	background-color: gray;
	border-bottom: 2px solid gray;
}
form input[type='checkbox']:focus-visible,
form input[type='checkbox']:checked:focus-visible {
	border-color: blue;
} */
