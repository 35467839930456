#faq {
	/* background: #eee; */
	min-height: 10vh;
	/* display: flex;
	flex-direction: column;
	align-items: center; */
	/* justify-content: center; */
	/* font-size: calc(10px + 2vmin); */
	/* position: relative; */
	/* padding: 1rem; */
	overflow: hidden;
}

/* #faq .title {
	color: var(--c-offwhite);
} */
#faq .divider .wave svg {
	fill: var(--c-bg-contact);
}

/* .faqs {
	width: 100%;
	max-width: 768px;
	margin: 0 auto; 
}
*/

.faqs .faq {
	margin: 15px 5px;
	padding: 10px;
	/* background: #fff; */
	border-radius: 10px;
	box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.1);
	/* display: block; */
	/* background: 0 0; */
	/* border: none; */
	/* font-size: 18px; */
	/* font-family: inherit; */
}
.faqs .faq:hover {
	/* transform: translateY(5px); */
	box-shadow: 0px 0px 2px 2px var(--c-red-orange);
}

.faqs .faq .faq-question {
	position: relative;
	/* font-size: var(--f-txt); */
	font-size: calc(var(--f-txt) + 0.3vmin);
	padding-right: 20px;
	transition: all 0.4s ease;
}

.faqs .faq .faq-question::after {
	font-weight: 700;
	content: '+';
	position: absolute;
	top: 0;
	right: 5px;
	transition: all 0.2s ease;
}

.faqs .faq .faq-answer {
	opacity: 0;
	max-height: 0;
	font-size: var(--f-txt);
	overflow: hidden;
	transition: all 0.2s ease;
	/* white-space: pre-line; */
}
.faqs .faq .faq-answer a {
	/* text-decoration: underline; */
	cursor: pointer;
	color: var(--c-red-orange);
}
.faqs .faq .faq-answer a:hover {
	/* opacity: 0.7; */
	text-decoration: underline;
}
.faqs .faq.open .faq-question {
	margin-bottom: 15px;
}

.faqs .faq.open .faq-question::after {
	content: '-';
}

.faqs .faq.open .faq-answer {
	max-height: 150px;
	overflow-y: scroll;
	opacity: 1;
}

/* this section doesnt get bigget then 768px, so this is the only query we need */
/* @media only screen and (min-width: 480px) {
	.faqs .faq {
		margin: 15px 10px;
		padding: 15px;
	}
	.faqs .faq .faq-question::after {
		right: 10px;
	}
} */

.faq-modal {
	opacity: 0;
	transform: translateY(100%);
	transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.faq-modal.show {
	opacity: 1;
	transform: translateY(0);
}
