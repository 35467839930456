.ant-image {
	height: 100% !important;
}
.ant-image-preview-switch-right,
.ant-image-preview-switch-left {
	@apply hidden;
}
.custom-switch-right,
.custom-switch-left,
.ant-image-preview-close,
.gallery-preview-button {
	@apply flex text-2xl fixed z-20 text-white backdrop-blur-md bg-black/30 p-5 md:p-8 rounded-full outline-white outline-2 hover:outline cursor-pointer transition-all duration-300 select-none touch-none;
}
.ant-image-preview-img svg,
.ant-image-preview-img span {
	user-select: none;
	pointer-events: none;
}
.ant-image-preview-img {
	@apply !max-w-[90vw] h-auto !max-h-[75vh] align-middle cursor-grab select-none;
}
.ant-image-mask-info {
	@apply select-none;
}
.ant-image-preview-mask {
	@apply !bg-black bg-opacity-70;
}

.ant-image-preview-footer {
	width: 100%;
}

.ant-image-preview-progress {
	@apply hidden;
}
/* .custom-progress {
	@apply backdrop-blur-md bg-black/30 rounded-full p-2 mb-1 absolute left-6 bottom-0 select-none touch-none w-max;
} */

.image-grid-item {
	@apply w-[95vw] max-w-[500px] md:w-[30vw];
}
/* .page {
	position: absolute;
	width: 100%;
	height: 100%;
	touch-action: none;
} */

/* .page > div {
	box-shadow: 0 62.5px 125px -25px rgba(50, 50, 73, 0.5), 0 37.5px 75px -37.5px rgba(0, 0, 0, 0.6);
} */

.swipe-left {
	transform: translateX(-100%);
	transition: transform 0.5s ease;
}

.swipe-right {
	transform: translateX(100%);
	transition: transform 0.5s ease;
}
.react-draggable {
	@apply cursor-grab;
}
.react-draggable-dragging {
	@apply cursor-grabbing;
}
