#contact .contact_method {
	display: flex;
	flex-direction: column;
	font-size: 1.5rem;
}

#contact fieldset {
	align-content: center;
	align-items: center;
	border: none;
	color: var(--c-text-dark);
	display: flex;
	/* display:inline; */
	font: 1em Verdana, Geneva, sans-serif;
	text-align: center;
	text-transform: none;
	justify-content: center;
}
/* hiding the input button so my button is the only visible part */
#contact fieldset input {
	display: none;
}

#contact fieldset label {
	align-items: center;
	border-radius: 10px;
	display: flex;
	background: rgba(33, 33, 33, 0.5);
	color: white;
	/* this adds the space around the button */
	margin: 0.5rem;
	/* this helps the background be visible */
	padding: 1rem;
}

#contact fieldset label h6 {
	/* add space between text and icon */
	/* margin-left: 10px; */
	/* by default we hide the button and inline it only when hovered or clicked */
	display: none;
	animation: slide-in-right-half 0.5s cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
}
#contact fieldset label:hover h6
/* #contact fieldset input[type='radio']:checked + label h6 */ {
	display: inline;
}
#contact fieldset input[type='radio']:checked + label {
	background: #000;
}

/* @media (min-width: 480px) {
	#contact fieldset label {
		flex-direction: row;
	}
	#contact fieldset label h6 {
		add space between text and icon 
		margin-left: 10px;
	}
} */
